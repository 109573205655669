<template>
  <div style="position: relative;" class="px-3">
    <!-- table result - loading -->
    <div v-if="loading" class="d-flex align-center justify-center" style="height: 86vh; width: 100%">
      <socket></socket>
    </div>

    <template v-else>
      <div class="d-flex align-center justify-space-between mb-1">
        <div class="d-flex align-end">
          <!-- Search for project -->
          <div class="d-flex align-center">
            <v-text-field v-model="projectSearch" @input="searchForProjects" hide-details dense outlined multiple :label="translations.findProject" background-color="#fff" palceholder="" class="project-search">
              <template v-slot:append>
                <div class="d-flex align-center justify-end project-count">
                  <span v-if="projectSearch !== ''">{{ filteredProjectsActive }} / {{ filteredProjectsLength }}</span>
                </div>
              </template>
            </v-text-field>

            <div class="d-flex align-center mr-2" style="min-width: 20px; width: 20px; height:40px;">
              <div>
                <div style="height: 20px;" class="d-flex align-top">
                  <v-btn @click="scrollToNextProject({ value: false, text: projectSearch })" icon x-small :disabled="disableScrollBtns"
                    ><v-icon color="main">{{ icons.mdiArrowUpDropCircle }}</v-icon></v-btn
                  >
                </div>
                <div style="height: 20px;" class="d-flex align-end">
                  <v-btn @click="scrollToNextProject({ value: true, text: projectSearch })" icon x-small :disabled="disableScrollBtns"
                    ><v-icon color="main">{{ icons.mdiArrowDownDropCircle }}</v-icon></v-btn
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- production center select -->
          <template v-if="productionCenterList.length > 0">
            <div>
              <v-select v-model="activeProductionCentersCopy" @change="changeReadTimeline" :items="productionCenterList" hide-details dense outlined multiple :label="translations.selectProductionCenter" background-color="#fff" class="mr-2">
                <template v-slot:selection=""> </template>
              </v-select>
            </div>

            <div v-if="activeProductionCenters.length > 0">
              <div style="margin-bottom: 2px; line-height: 1">
                <small>{{ translations.activeProductionCenters }}:</small>
              </div>

              <div>
                <v-chip v-for="productionCenter in activeProductionCenters" @click:close="removeProductionCenter(productionCenter)" color="main" class="mr-1" :key="`chip-${productionCenter}`" small label outlined close>{{productionCenter }}</v-chip>
              </div>
            </div>
          </template>
        </div>

        <div>
          <!-- Scissor btn -->
          <v-btn @click="(setScissorMode = !setScissorMode), (options.selectable = !options.selectable), removeSelected()" :color="!setScissorMode ? 'main' : 'white'" class="mr-2" dark fab small :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }">
            <v-icon :color="!setScissorMode ? 'white' : 'main'">{{ !setScissorMode ? icons.mdiContentCut : icons.mdiScissorsCutting }}</v-icon>
          </v-btn>

          <!-- Project Ordering Dialog Activator -->
          <v-btn @click="showProjectOrderDialog = true" color="main" class="mr-2" dark fab small>
            <v-icon>{{ icons.mdiOrderNumericAscending }}</v-icon>
          </v-btn>

          <!-- Squads and employees menu -->
          <v-menu offset-y bottom nudge-left :close-on-click="false" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="main mx-2" dark v-bind="attrs" v-on="on" fab small>
                <v-icon>{{ icons.mdiAccountSearch }}</v-icon>
              </v-btn>
            </template>
            <v-card class="card-lists-tabs">
              <v-tabs v-model="tab">
                <v-tab v-for="item in ['Squadre', 'Addetti', 'Fornitori']" :key="item">
                  {{ item }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <div>
                    <div>
                      <v-text-field v-model="squadNameFilter" class="my-2 mx-1" solo hide-details :placeholder="translations.typeSquadName"></v-text-field>
                    </div>
                    <div style="max-height: 250px; overflow-y: scroll;" class="scroll-bar">
                      <v-tooltip v-for="squad in squadList" :key="squad.id" left color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" :draggable="!canReadOnly" class="d-flex align-center justify-space-between mx-1 hover-row pa-1" :id="`squad-${squad.id}`" @dragstart="onDragStart">
                            <div class="d-inline-block">
                              <strong>
                                {{ squad.name }}
                              </strong>
                            </div>
                            <div class="d-inline-block">
                              <v-icon size="17"> {{ icons.mdiArrowAll }}</v-icon>
                            </div>
                          </div>
                        </template>
                        <ul>
                          <li v-for="employee in squad.employees" :key="`${squad.id}-${employee.id}`">
                            {{ employee.name }}
                          </li>
                        </ul>
                      </v-tooltip>

                      <div v-if="hasNextSquads" class="d-flex justify-center">
                        <v-progress-circular v-intersect.quiet="onIntersect" size="24" indeterminate color="main"></v-progress-circular>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <!-- employee list -->
                <v-tab-item>
                  <div>
                    <div>
                      <v-text-field v-model="employeeNameFilter" class="my-2 mx-1" solo hide-details :placeholder="translations.filterForEmployee"></v-text-field>
                    </div>

                    <div style="max-height: 250px; height: 250px; overflow-y: scroll;" class="scroll-bar">
                      <template v-if="employeesComputed && employeesComputed.length > 0">
                        <div
                          v-for="employee in employeesComputed"
                          :key="`employee-${employee.id}`"
                          :draggable="!canReadOnly"
                          class="d-flex align-center justify-space-between mx-1 hover-row pa-1"
                          :id="`employee-${employee.id}`"
                          @dragstart="onDragStart"
                        >
                          <div class="d-inline-block">
                            <strong>
                              {{ employee.name }}
                            </strong>
                          </div>
                          <div class="d-inline-block">
                            <v-icon size="17"> {{ icons.mdiArrowAll }}</v-icon>
                          </div>
                        </div>
                      </template>

                      <div v-else class="pa-1">
                        <span>{{ translations.noEmployeesFoundWThatName }}</span>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <!-- Suppliers list -->
                <v-tab-item>
                  <div>
                    <div>
                      <v-text-field v-model="supplierNameFilter" class="my-2 mx-1" solo hide-details :placeholder="translations.typeSupplierName"></v-text-field>
                    </div>

                    <div style="max-height: 250px; height: 250px; overflow-y: scroll;" class="scroll-bar">
                      <template v-if="filterSuppliers && filterSuppliers.length > 0">
                        <div
                          v-for="supplier in filterSuppliers"
                          :key="`supplier-${supplier.id}`"
                          :draggable="!canReadOnly"
                          class="d-flex align-center justify-space-between mx-1 hover-row pa-1"
                          :id="`supplier-${supplier.id}`"
                          @dragstart="onDragStart"
                        >
                          <div class="d-inline-block">
                            <strong>
                              {{ supplier.name }}
                            </strong>
                          </div>
                          <div class="d-inline-block">
                            <v-icon size="17"> {{ icons.mdiArrowAll }}</v-icon>
                          </div>
                        </div>
                      </template>

                      <div v-else class="pa-1">
                        <span>{{ translations.noSupplierFoundWThatName }}</span>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-menu>
        </div>
      </div>

      <timeline
        @hook:mounted="setAdditionalAttributes"
        ref="timeline"
        :items="items"
        :groups="groups"
        :options="options"
        @drop="handleProjectDrop"
        @double-click="handleStartCreation"
        @rangechanged="changeRangeHandler"
        @click="handleClick"
        @itemover="itemOverHandler"
        @changed="timelineRedrawn"
      ></timeline>

      <!-- Project Dialog -->
      <project-summary-dialog
        v-if="showProjectDialog"
        :show="showProjectDialog"
        :project="selectedProject"
        :employeeList="employeeList"
        :supplierList="supplierList"
        :canReadOnly="canReadOnly"
        @close="showProjectDialog = false"
        @delete-timeline="deleteItemFromDialog"
        @edit-timeline="editFromDialog"
      ></project-summary-dialog>

      <!-- Project Status Menu -->
      <project-status-menu
        v-show="showStatusMenu"
        id="project-status-menu"
        :show="showStatusMenu"
        :key="statusMenuKey"
        :statusList="statusList"
        :currentStatus="selectedProjectStatus"
        :canReadOnly="canReadOnly"
        @close="closeHandler"
        @status-change="statusChangeHandler"
        style="z-index: 999"
      ></project-status-menu>

      <foreman-menu
        v-show="showForemanMenu"
        id="foreman-menu"
        :show="showForemanMenu"
        :currentSort="selectedProject.sort"
        :currentCategory="selectedProject.category"
        :categoryList="foremanCategories"
        :canReadOnly="canReadOnly"
        @close="closeHandler"
        @sort-change="sortChangeHandler"
        @category-change="categoryChangeHandler"
        style="z-index: 999"
      ></foreman-menu>

      <!-- Item Creation Dialog -->
      <item-creation-dialog
        v-show="showItemCreationDialog"
        id="item-creation-dialog"
        :show="showItemCreationDialog"
        :key="`item-creation-${ItemCreationDialogKey}`"
        :startingDate="newItemStartDate"
        :endingDate="newItemEndingDate"
        :entityGroup="groupToCreateItem"
        :loading="loadingCreation"
        @close="closeItemCreationDialogHandler"
        @create-item="createNewItem"
        style="z-index: 999"
      ></item-creation-dialog>

      <!-- Timeline Notes Dialog -->
      <timeline-notes-dialog
        v-if="showNotesDialog"
        :key="`update-notes-dialog-${notesDialogKey}`"
        :show="showNotesDialog"
        :timeline="timelineToUpdateNote"
        :loading="loadingNotesUpdates"
        @close="closeNotesDialogHandler()"
        @update-note="updateNoteHandler"
      ></timeline-notes-dialog>

      <!-- Project Ordering Dialog -->
      <project-ordering-dialog
        v-if="showProjectOrderDialog"
        :show="showProjectOrderDialog"
        :key="`project-order-dialog-${projectOrderDialogKey}`"
        :projects="groupsTypeProject"
        :loading="loadingOrderChange"
        :projectSearchProp="projectSearch"
        :filteredProjectsActive="filteredProjectsActive"
        :filteredProjectsLength="filteredProjectsLength"
        :canReadOnly="canReadOnly"
        @close="projectOrderDialogCloseHandler"
        @start-order-change="loadingOrderChange = true"
        @end-order-change="changeReadTimeline"
        @scroll-to-next-project="
          value => {
            scrollToNextProject(value);
          }
        "
        @search-for-projects="updateValueAndSearchForProjects"
      ></project-ordering-dialog>

      <!-- message dialog -->
      <message-dialog :show="showErrorMsgDialog">
        <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
        </template>

        <template v-slot:message>
          <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
            {{ errorMessage }}
          </div>
        </template>

        <template v-slot:card-action>
          <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
          </div>
        </template>
      </message-dialog>
    </template>

    <!-- Expand Collapse Buttons -->
    <div v-if="mountExpandActions" class="expand-collapse-buttons">
      <div class="d-flex">
        <v-btn @click="expandAllProjects()" small class="mr-2" :elevation="1"
          ><v-icon small>{{ icons.mdiArrowExpand }}</v-icon> {{ translations.expand }}</v-btn
        >
        <v-btn @click="collapseAllProjects()" small :elevation="1"
          ><v-icon small>{{ icons.mdiArrowCollapse }}</v-icon> {{ translations.collapse }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
// import uniqBy from "lodash/uniqBy";
import { Timeline } from "vue2vis";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";
import { mdiAccountSearch, mdiArrowAll, mdiCog, mdiArrowDownDropCircle, mdiArrowUpDropCircle, mdiOrderNumericAscending, mdiTruckDeliveryOutline, mdiArrowCollapse, mdiArrowExpand, mdiContentCut, mdiScissorsCutting } from "@mdi/js";

import { Socket } from "vue-loading-spinner";
import debounce from "lodash/debounce";
import uniq from "lodash/uniq";

const ProjectSummaryDialog = () => import("./ProjectSummaryDialog.vue");
const ProjectStatusMenu = () => import("./ProjectStatusMenu.vue");
const ForemanMenu = () => import("./ForemanMenu.vue");
const ItemCreationDialog = () => import("./ItemCreationDialog.vue");
const TimelineNotesDialog = () => import("./TimelineNotesDialog.vue");
const MessageDialog = () => import("@/components/MessageDialog.vue");
const WarningSvg = () => import("@/assets/WarningSvg.vue");
const ProjectOrderingDialog = () => import("./ProjectOrderingDialog.vue");

import squadsQuery from "@/graphql/Squads.gql";
import readTimelineQuery from "@/graphql/ReadTimelineData.gql";
import saveOrUpdateTimelineMutation from "@/graphql/SaveOrUpdateTimeline.gql";
import toggleEmployeeForemanMutation from "@/graphql/ToggleEmployeeForeman.gql";
import updateForemanCategory from "@/graphql/UpdateForemanCategory.gql";
import updateForemanSort from "@/graphql/UpdateForemanSort.gql";
import deleteTimelineMutation from "@/graphql/DeleteTimeline.gql";
import getProjectStatusListQuery from "@/graphql/GetProjectStatusList.gql";
import updateProjectStatusMutation from "@/graphql/UpdateProjectStatus.gql";
import updateNote from "@/graphql/UpdateNote.gql";
import availableEmployeesByEndDateQuery from "@/graphql/AvailableEmployeesByEndDate.gql";
import suppliersQuery from "@/graphql/Suppliers.gql";

import { translations } from "@/utils/common";
import { createSpan, createForemanSpan } from "@/utils/common/domBuilder";
import { getForemanCategories } from "@/utils/api/config.js";

export default {
  name: "LongTermPlanner",

  components: {
    Timeline,
    ProjectSummaryDialog,
    ProjectStatusMenu,
    ForemanMenu,
    ItemCreationDialog,
    TimelineNotesDialog,
    MessageDialog,
    WarningSvg,
    ProjectOrderingDialog,
    Socket
  },

  props: {
    productionCenterList: {
      type: Array,
      default: () => []
    },
    activeProductionCenters: {
      type: Array,
      default: () => []
    },
    canReadOnly: {
      type: Boolean,
      default: false
    }
  },

  apollo: {
    getProjectStatusList: {
      query: getProjectStatusListQuery,
      fetchPolicy: "network-only"
    },

    //set variables on created using props from parent
    readTimelineData: {
      query: readTimelineQuery,
      fetchPolicy: "no-cache",
      skip: true,
      result({ data }) {
        for (const project of Object.keys(data.readTimelineData)) {
          const { groups, items } = data.readTimelineData[project];

          let order = 0;

          const mappedGroups = groups.map(group => {
            const { type, id } = group;

            if (type === "PROJECT") {
              order = group?.project?.position;
              return {
                ...group,
                order: order,
                className: `actual-project group-id-${id}`,
                showNested: false
              };
            }

            return {
              ...group,
              order: `${order}-${type[0]}-${group.content}`
            };
          });

          const mappedItems = items.map(item => {
            const { startDateStr, endDateStr, group, warning, itemType, note, content, ...everythingElse } = item;
            const start = `${startDateStr} 06:00:00`;
            const end = `${endDateStr} 18:00:00`;

            // PROJECT item
            if (itemType === "PROJECT") {
              return {
                start,
                end,
                group,
                className: `vis-item-project-scope-${warning}`,
                itemType,
                ...everythingElse
              };
            }

            // SUMMARY item
            else if (itemType === "SUMMARY") {
              return {
                start,
                end,
                group,
                className: `vis-item-nested-project-${warning} vis-item-summary`,
                itemType,
                warning,
                ...everythingElse,
                content: ""
              };
            }

            // EMPLOYEE SUPPLIER items
            return {
              start,
              end,
              group,
              className: `vis-item-nested-project-${warning} vis-item-scissor`,
              itemType,
              warning,
              title: note,
              name: content,
              ...everythingElse,
              content: note ? note : "",
              editable: {
                remove: true,
                updateGroup: false,
                updateTime: true
              }
            };
          });

          this.groups.push(...mappedGroups);
          this.items.push(...mappedItems);
        }

        this.loading = false;
      }
    },

    availableEmployeesByEndDate: {
      query: availableEmployeesByEndDateQuery,
      fetchPolicy: "network-only"
    },

    suppliers: {
      query: suppliersQuery,
      fetchPolicy: "network-only"
    }
  },

  data() {
    return {
      tab: 0,
      showProjectDialog: false,
      draggingItems: [],
      employeeList: [],
      supplierList: [],
      foremanCategories: [],
      selectedProject: {},
      selectedProjectStatus: 0,
      showStatusMenu: false,
      showForemanMenu: false,
      statusMenuKey: 0,
      loading: true,
      hasNextSquads: true,
      squadList: [],
      squadPage: 1,
      squadNameFilter: "",
      employeeNameFilter: "",
      groups: [],
      items: [],
      showItemCreationDialog: false,
      ItemCreationDialogKey: 0,
      groupToCreateItem: {},
      newItemStartDate: "",
      newItemEndingDate: "",
      loadingCreation: false,
      timelineToUpdateNote: {},
      loadingNotesUpdates: false,
      notesDialogKey: 0,
      showNotesDialog: false,
      availableRange: {
        start: "",
        end: ""
      },
      options: {
        editable: {
          add: false,
          updateTime: true,
          updateGroup: false,
          remove: true,
          overrideItems: false
        },
        groupEditable: {
          add: true,
          remove: true,
          order: false
        },
        selectable: !this.canReadOnly,
        locale: "it",
        align: "left",
        autoResize: true,
        orientation: "top",
        zoomable: false,
        start: `${this.formatDate(new Date())} 06:00:00`,
        end: `${this.getEndDate(45)} 18:00:00`,
        showCurrentTime: false,
        timeAxis: { scale: "day", step: 1 },
        stack: false,
        maxHeight: "86vh",
        verticalScroll: true,
        showTooltips: false,

        groupOrder: (a, b) => {
          if (a.type === "PROJECT" && b.type === "PROJECT") {
            return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
          } else if (a.type !== "PROJECT" && b.type !== "PROJECT") {
            const aNumOrder = Number(a.order.split("-")[0]);
            const bNumOrder = Number(b.order.split("-")[0]);

            return aNumOrder > bNumOrder ? 1 : bNumOrder > aNumOrder ? -1 : a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
          }

          const aOrder = typeof a.order === "number" ? a.order : Number(a.order.split("-")[0]);
          const bOrder = typeof b.order === "number" ? b.order : Number(b.order.split("-")[0]);
          return aOrder > bOrder ? 1 : bOrder > aOrder ? -1 : 0;
        },

        groupTemplate: (item, element) => {
          if (item) {
            // this is for projects
            if (item.type === "PROJECT") {
              const wrapper = document.createElement("div");
              wrapper.classList.add(`actual-project`);
              wrapper.classList.add(`project-template`);
              wrapper.classList.add(`group-id-${item.id}`);

              const projectName = document.createElement("div");
              projectName.classList.add("project-code-name", `${item.project.keyCode}`);
              projectName.innerText = item.project.keyCode + " " + item.project.label;
              projectName.title = item.content;

              const iconContainer = document.createElement("div");
              iconContainer.classList.add("icon-container");
              iconContainer.setAttribute("data-id", item.id);

              const icons = [
                { icon: "mdi-cog", class: "settings-icon" },
                { icon: "mdi-format-list-bulleted-square", class: "list-icon" }
              ];

              icons.forEach(icon => {
                const iconDiv = document.createElement("div");
                iconDiv.classList.add("d-block");

                const iconEl = document.createElement("i");
                iconEl.classList.add("mdi");
                iconEl.classList.add(icon.icon);
                iconEl.classList.add(icon.class);
                iconEl.setAttribute("data-id", item.id);

                iconDiv.append(iconEl);
                iconContainer.appendChild(iconDiv);
              });

              wrapper.appendChild(projectName);
              wrapper.appendChild(iconContainer);

              return wrapper;
            }

            // this is for employee
            if (item.type === "EMPLOYEE") {
              const wrapper = document.createElement("div");
              wrapper.classList.add("group-template-items");

              // foreman checkbox
              const checkbox = document.createElement("input");
              checkbox.setAttribute("type", "checkbox");
              checkbox.setAttribute("id", `employee-${item.id}`);
              checkbox.style.marginRight = "8px";
              checkbox.disabled = this.canReadOnly

              checkbox.addEventListener("change", e => {
                  const { checked } = e.target;
                  const grandParent = e.target.parentNode.parentNode;
                  const ids = e.target.id.split("-");

                  const projectId = Number(ids[1].split("P")[1]);
                  const employeeId = Number(ids[2].split("E")[1]);
                  const success = this.toggleEmployeeForeman(checked, employeeId, projectId);
                  if (!success) return;

                  const groupId = e.target.id.slice(9);
                  const group = this.groups.find(group => group.id === groupId);

                  if (checked) {
                    grandParent.classList.remove("group-template-not-foreman");
                    grandParent.classList.add("group-template-is-foreman");
                    group.foreman = true;
                  } else {
                    grandParent.classList.remove("group-template-is-foreman");
                    grandParent.classList.add("group-template-not-foreman");
                    group.foreman = false;
                  }
                  group.category = null;
                  group.sort = null;
                }, false
              );

              if (item.foreman) {
                checkbox.setAttribute("checked", true);
              }

              wrapper.appendChild(checkbox);

              const employeeSpan = createSpan({
                innerElement: item.content,
                color: "#ffffff",
                margin: "0 .2em"
              });
              wrapper.appendChild(employeeSpan);

              // foreman's attributes (category and sort)
              if (item.foreman) {
                wrapper.appendChild(this.buildForemanTemplate(item));
              }

              element.classList.add("group-template");

              if (item.foreman) {
                element.classList.add("group-template-is-foreman");
              } else {
                element.classList.add("group-template-not-foreman");
              }

              return wrapper;
            }

            // supplier template
            const wrapper = document.createElement("div");
            wrapper.classList.add("group-template-items-supplier-wrapper");

            const iconDiv = document.createElement("div");
            iconDiv.classList.add("d-block");

            const iconEl = document.createElement("i");
            iconEl.classList.add("mdi");
            iconEl.classList.add("mdi-truck-delivery-outline");
            iconEl.classList.add("supplier-icon");

            iconDiv.append(iconEl);

            const span = document.createElement("span");
            span.innerText = item.content;

            wrapper.appendChild(iconDiv);
            wrapper.appendChild(span);

            element.classList.add("group-template");
            element.classList.add("group-template-supplier");

            return wrapper;
          }
        },

        onMove: async (item, callback) => {
          const editResponse = await this.editTimeline(item);

          if (!editResponse?.error) return;

          callback(editResponse.item);
        },

        onRemove: item => {
          this.deleteItemHandler(item);
        }
      },
      showErrorMsgDialog: false,
      errorMessage: "",
      activeProductionCentersCopy: [],
      projectSearch: "",
      filteredProjectsLength: 0,
      filteredProjectsActive: 0,
      filteredProjects: [],
      scrollOptions: {
        behaviour: "smooth",
        block: "start"
      },
      showProjectOrderDialog: false,
      projectOrderDialogKey: 0,
      loadingOrderChange: false,
      supplierNameFilter: "",
      mountExpandActions: false,
      setScissorMode: false
    };
  },

  created() {
    this.availableRange.start = this.formatDate(new Date());
    this.availableRange.end = this.getEndDate(45);

    const readTimlineVariables = {
      startDateStr: this.formatDate(new Date()),
      endDateStr: this.getEndDate(45)
    };

    if (this.activeProductionCenters.length > 0) readTimlineVariables["productionCenterList"] = this.activeProductionCenters;

    this.$apollo.queries.readTimelineData.setVariables(readTimlineVariables);
    this.$apollo.queries.readTimelineData.skip = false;

    this.activeProductionCentersCopy = this.activeProductionCenters;

    this.foremanCategories = getForemanCategories();
  },

  mounted() {
    this.loadQuerySquads();
  },

  mixins: [MomentMixin],

  methods: {
    buildForemanTemplate: (item) => {
      const category = getForemanCategories().find(c => c.code === item.category);

      const foremanWrapper = document.createElement("div");
      foremanWrapper.classList.add("foreman-wrapper");
      foremanWrapper.setAttribute("data-id", item.id);
      if (category) {
        foremanWrapper.title = category.name;
      }

      // add change foreman category icon
      const iconEl = document.createElement("i");
      iconEl.classList.add("mdi");
      iconEl.classList.add('mdi-crown');
      iconEl.classList.add('settings-icon');
      iconEl.setAttribute("data-id", item.id);
      foremanWrapper.appendChild(iconEl);

      const categorySpan = createForemanSpan({
        dataId: item.id,
        innerElement: item.category,
        color: category ? category.color : '#FFFFFF',
        fontWeight: '450'
      });
      foremanWrapper.appendChild(categorySpan);

      const sortSpan = createForemanSpan({
        dataId: item.id,
        innerElement: item.sort,
        color: '#EEEEEE',
        fontWeight: '400'
      });
      foremanWrapper.appendChild(sortSpan);

      return foremanWrapper;
    },

    async loadQuerySquads() {
      try {
        const { data } = await this.$apollo.query({
          query: squadsQuery,
          variables: {
            page: 1,
            pageSize: 9,
            sort: "name",
            order: "asc",
            name: this.squadNameFilter
          },
          fetchPolicy: "network-only"
        });

        this.hasNextSquads = data.squads.hasNext;
        this.squadList.push(...data.squads.content);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchMoreSquads();
      }
    },

    async fetchMoreSquads() {
      try {
        this.squadPage++;

        const { data } = await this.$apollo.query({
          query: squadsQuery,
          variables: {
            page: this.squadPage,
            pageSize: 9,
            sort: "name",
            order: "asc",
            name: this.squadNameFilter
          }
        });

        this.hasNextSquads = data.squads.hasNext;
        this.squadList.push(...data.squads.content);
      } catch (e) {
        console.log(e);
      }
    },

    getFilteredQuads: debounce(function () {
      this.squadList = [];
      this.squadPage = 1;
      this.loadQuerySquads();
    }, 650),

    async editTimeline(item) {
      const { timelineId, start, end, group, name } = item;
      const itemFromList = this.items.find(item => item.timelineId === timelineId);
      try {
        //Get projecId
        const ids = group.split("-");
        const projectGroup = this.groups.find(group => group.id === ids[0]);
        const projectId = projectGroup?.project?.id;
        const employeeId = ids[1].includes("E") ? Number(ids[1].split("E")[1]) : null;
        const supplierId = ids[1].includes("S") ? Number(ids[1].split("S")[1]) : null;

        const itemGroup = this.groups.find(singleGroup => singleGroup.id === group);

        const payload = {
          isForeman: itemGroup?.foreman ? true : false,
          timelineId: timelineId,
          employeeId: employeeId,
          supplierId: supplierId,
          startDateStr: this.formatDate(start),
          projectId: projectId,
          endDateStr: this.formatDate(end)
        };

        const { data } = await this.$apollo
          .mutate({
            mutation: saveOrUpdateTimelineMutation,
            variables: payload
          })
          .catch(error => {
            let errorMsg = this.translations.unableToUpdateTimeline;
            if (error.message.includes("end date exceeds employee")) {
              const msgArray = error.message.split(":")[3].split(" ");

              const contractEndDate = this.momentDate(msgArray[1].replaceAll("'", ""));
              errorMsg = `${this.translations.updateError}: ${this.translations.timelineDateForEmployee} ${name} ${this.translations.exceedsEndDate}: ${contractEndDate}.`;
            }

            this.errorHandler(errorMsg);
            return { error: true, item: itemFromList };
          });

        const edited = data.saveOrUpdateTimeline;
        this.prepNewData(edited);

        return edited;
      } catch (e) {
        console.log(e);
        return { error: true, item: itemFromList };
      }
    },

    getOriginalInfo(projectId) {
      const originalProject = this.groups.find(group => group?.project?.id == projectId);
      const originalProjectNestedGroups = originalProject.nestedGroups;
      const originalGroups = this.groups.filter(group => originalProjectNestedGroups.includes(group.id));

      const groupsIds = [originalProject.id, ...originalProjectNestedGroups];
      const ogEmployeeSupplierItems = this.items.filter(item => {
        return groupsIds.includes(item.group) && (item.itemType === "EMPLOYEE" || item.itemType === "SUPPLIER");
      });

      return {
        originalGroups: [originalProject, ...originalGroups],
        ogEmployeeSupplierItems: ogEmployeeSupplierItems
      };
    },

    handleProjectComparison(original, edited) {
      const { originalGroups, ogEmployeeSupplierItems } = original;
      const { items, groups, summaryItems } = edited;
      const originalProjectGroupId = originalGroups.find(group => group.type === "PROJECT").id;
      const itemsWithoutProjecType = items.filter(item => item.itemType !== "PROJECT");

      // find groups to delete or add
      const ogGroupIds = originalGroups.map(group => group.id);
      const groupIds = groups.map(group => group.id);
      const groupsToDelete = ogGroupIds.filter(id => !groupIds.includes(id));
      const groupsToAdd = groupIds.filter(id => !ogGroupIds.includes(id));

      //find items to delete or add
      const ogItemsIds = ogEmployeeSupplierItems.map(item => item.timelineId);
      const itemIds = itemsWithoutProjecType.map(item => item.timelineId);
      const itemsToDelete = ogItemsIds.filter(id => !itemIds.includes(id));
      const itemsToAdd = itemIds.filter(id => !ogItemsIds.includes(id));
      //Remove items
      if (itemsToDelete.length > 0) {
        itemsToDelete.forEach(timelineId => {
          const itemIndex = this.items.findIndex(item => item.timelineId === timelineId);
          this.items.splice(itemIndex, 1);
        });
      }

      this.items = this.items.filter(item => {
        const { group, itemType } = item;
        const groupId = group.split("-")[0];

        if ((groupId === originalProjectGroupId && itemType !== "SUMMARY") || groupId !== originalProjectGroupId) return item;
      });

      //add missing Items
      summaryItems.forEach(sItem => {
        const { startDateStr, endDateStr, days, warning, group, itemType, ...everythingElse } = sItem;

        const start = `${startDateStr} 06:00:00`;
        const end = `${endDateStr} 18:00:00`;

        const itemReady = {
          ...everythingElse,
          start,
          end,
          group,
          className: `vis-item-nested-project-${warning} vis-item-summary`,
          itemType: itemType,
          warning,
          days,
          content: "",
          editable: false
        };

        this.items.push(itemReady);
      });

      if (itemsToAdd.length > 0) {
        itemsToAdd.forEach(itemId => {
          const itemToAdd = itemsWithoutProjecType.find(item => item.timelineId === itemId);
          const { startDateStr, endDateStr, days, warning, group, itemType, ...everythingElse } = itemToAdd;

          const start = `${startDateStr} 06:00:00`;
          const end = `${endDateStr} 18:00:00`;

          const itemReady = {
            ...everythingElse,
            start,
            end,
            group,
            className: itemType === "SUMMARY" ? `vis-item-nested-project-${warning} vis-item-summary` : `vis-item-nested-project-${warning} vis-item-scissor`,
            itemType: itemType,
            warning,
            days,
            editable:
              itemType !== "SUMMARY"
                ? {
                    remove: true,
                    updateGroup: false,
                    updateTime: true
                  }
                : false
          };

          this.items.push(itemReady);
        });
      }

      if (groupsToDelete.length > 0) {
        groupsToDelete.forEach(groupId => {
          const groupIndex = this.groups.findIndex(group => group.id === groupId);
          this.groups.splice(groupIndex, 1);
        });
      }

      if (groupsToAdd.length > 0) {
        const projectOrder = originalGroups.find(group => group.id === originalProjectGroupId).order;
        groupsToAdd.forEach(groupId => {
          const foundGroup = groups.find(group => group.id === groupId);
          const groupToAdd = { ...foundGroup, order: `${projectOrder}-${foundGroup.type[0]}-${foundGroup.content}` };

          this.groups.push(groupToAdd);
        });
      }

      //handle groups change
      for (const group of originalGroups) {
        const editedGroup = groups.find(edGroup => edGroup.id === group.id);

        if (editedGroup) {
          group.nestedGroups = [...editedGroup.nestedGroups];
        }
      }

      //handle items change
      for (const item of ogEmployeeSupplierItems) {
        const editedItem = itemsWithoutProjecType.find(edItem => edItem.timelineId === item.timelineId);

        if (editedItem) {
          const { days, endDateStr, startDateStr, warning } = editedItem;

          item.days = days;
          item.end = `${endDateStr} 18:00:00`;
          item.start = `${startDateStr} 06:00:00`;
          item.className = item.itemType === "SUMMARY" ? `vis-item-nested-project-${warning} vis-item-summary` : `vis-item-nested-project-${warning} vis-item-scissor`;
          item.warning = warning;
        }
      }

      const ogGroup = this.groups.find(group => group.id == originalProjectGroupId);

      ogGroup.showNested = true;
    },

    // At the time draging starts
    onDragStart(event) {
      event.dataTransfer.effectAllowed = "move";
      let items = "";
      const dragSrcEl = event.target;

      const draggingItem = dragSrcEl.id.split("-");
      const type = draggingItem[0];
      const id = draggingItem[1];
      if (type === "squad") {
        const squad = this.squadList.find(squad => squad.id == id);
        this.draggingItems = squad.employees.map(employee => {
          return { ...employee, type: "employee" };
        });

        items = JSON.stringify({
          id: Math.floor(Math.random() * 500),
          content: squad.employees
        });
      } else if (type === "supplier") {
        const supplier = this.filterSuppliers.find(supplier => supplier.id == id);
        supplier["type"] = "supplier";
        this.draggingItems = [supplier];
        items = JSON.stringify({
          id: Math.floor(Math.random() * 500),
          content: supplier.name
        });
      } else {
        const employee = this.availableEmployeesByEndDate.find(employee => employee.id == id);
        employee["type"] = "employee";
        //keep in array form to keep same logic
        this.draggingItems = [employee];
        items = JSON.stringify({
          id: Math.floor(Math.random() * 500),
          content: employee.name
        });
      }

      event.dataTransfer.setData("text/plain", items);
    },

    async handleProjectDrop(event) {
      // id of group being dragged into
      const groupId = event.group.split("-")[0];

      const parentGroup = this.groups.find(group => group.id == groupId);
      const projectId = parentGroup?.project?.id;
      if (!projectId) return;

      const start = this.formatDate(event.time);
      const end = this.getEndDate(5, event.time);

      let editedData = {};
      for (const item of this.draggingItems) {
        const { id, name, type } = item;

        const payload = {
          timelineId: null,
          startDateStr: start,
          projectId: projectId,
          endDateStr: end,
          isForeman: false
        };

        if (type === "supplier") {
          payload["supplierId"] = id;
        } else {
          payload["employeeId"] = id;

          // get foreman value from group
          const employeeGroup = this.groups.find(group => group.id == event.group)
          payload["isForeman"] = employeeGroup.foreman;
        }

        const { data } = await this.$apollo
          .mutate({
            mutation: saveOrUpdateTimelineMutation,
            variables: payload
          })
          .catch(error => {
            let errorMsg = this.translations.unableToUpdateTimeline;
            if (error.message.includes("end date exceeds employee")) {
              const msgArray = error.message.split(":")[3].split(" ");

              const contractEndDate = this.momentDate(msgArray[1].replaceAll("'", ""));
              errorMsg = `${this.translations.updateError}: ${this.translations.timelineDateForEmployee} ${name} ${this.translations.exceedsEndDate}: ${contractEndDate}.`;
            }

            this.errorHandler(errorMsg);
          });

        editedData = data.saveOrUpdateTimeline;
      }

      this.employeeList = editedData[groupId].items
        .filter(item => {
          const { itemType, group } = item;
          const projectGroupId = group.split("-")[0];
          if (itemType === "EMPLOYEE" && groupId === projectGroupId) return item;
        })
        .map(item => {
          const { startDateStr, endDateStr, content, ...everythingElse } = item;

          return {
            end: endDateStr,
            start: startDateStr,
            projectId: projectId,
            name: content,
            ...everythingElse
          };
        })
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

      this.supplierList = editedData[groupId].items
        .filter(item => {
          const { itemType, group } = item;
          const projectGroupId = group.split("-")[0];
          if (itemType === "SUPPLIER" && groupId === projectGroupId) return item;
        })
        .map(item => {
          const { startDateStr, endDateStr, content, ...everythingElse } = item;

          return {
            end: endDateStr,
            start: startDateStr,
            projectId: projectId,
            name: content,
            ...everythingElse
          };
        })
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

      let projectTotalDays = 0;
      this.employeeList.forEach(item => {
        const { days } = item;
        projectTotalDays += days;
      });

      this.prepNewData(editedData);
      this.selectedProject = { ...parentGroup, allocatedDays: projectTotalDays };
      this.draggingItems = [];
      this.showProjectDialog = true;
    },

    closeHandler() {
      this.showStatusMenu = false;
      this.showForemanMenu = false;
      this.selectedProject = {};
      this.selectedProjectStatus = 0;
      this.statusMenuKey++;
    },

    async statusChangeHandler(statusId) {
      try {
        const projectId = this.selectedProject?.project?.id;

        await this.$apollo
          .mutate({
            mutation: updateProjectStatusMutation,
            variables: {
              projectId: projectId,
              projectStatusId: statusId
            }
          })
          .catch(error => {
            console.log(error);
          });

        const parentId = `group-${this.selectedProject.id}`;
        const parentElement = document.getElementById(parentId);

        this.replaceClass(parentElement, "project-wrapper", `project-wrapper-${statusId}`);

        let foundGroup = this.groups.find(group => group?.project?.id === projectId);
        if (foundGroup) {
          foundGroup.project.projectStatus.id = statusId;
        }
        this.closeHandler();
      } catch (e) {
        console.log(e);
      }
    },

    async sortChangeHandler(sort) {
      try {
        const projectEmployeeId = this.selectedProject.id;
        const ids = projectEmployeeId.split("-");
        const projectId = Number(ids[0].split("P")[1]);
        const employeeId = Number(ids[1].split("E")[1]);

        await this.$apollo
          .mutate({
            mutation: updateForemanSort,
            variables: {
              projectId: projectId,
              employeeId: employeeId,
              newSort: sort
            }
          })
          .then(() => {
            this.selectedProject.sort = sort;
          })
          .catch(error => {
            console.warn(error);
          });
      } catch (e) {
        console.warn(e);
      }
    },

    async categoryChangeHandler(category) {
      try {
        const projectEmployeeId = this.selectedProject.id;
        const ids = projectEmployeeId.split("-");
        const projectId = Number(ids[0].split("P")[1]);
        const employeeId = Number(ids[1].split("E")[1]);

        await this.$apollo
          .mutate({
            mutation: updateForemanCategory,
            variables: {
              projectId: projectId,
              employeeId: employeeId,
              newCategory: category
            }
          })
          .then(() => {
            this.selectedProject.category = category;
          })
          .catch(error => {
            console.warn(error);
          });
      } catch (e) {
        console.warn(e);
      }
    },

    replaceClass(element, classString, classToReplace) {
      const classToRemove = element.classList.value.split(" ").find(c => c.includes(classString));

      element.classList.replace(classToRemove, classToReplace);
    },

    setAdditionalAttributes() {
      const groups = document.getElementsByClassName("vis-label actual-project");
      groups.forEach(group => {
        // get class attribute
        const classAttribute = group.getAttribute("class").split(" ");
        let groupId = classAttribute.find(attr => attr.includes("group-id")).split("-");
        groupId = groupId[groupId.length - 1];

        //Need to set proper status id for css class
        const foundGroup = this.groups.find(group => group.id === groupId);
        const statusId = foundGroup?.project?.projectStatus?.id;

        group.classList.add(`project-wrapper-${statusId}`);
        group.setAttribute("id", `group-${groupId}`);
      });

      this.turnOfHammerEvents();
      this.setScrollListenter();
      this.mountExpandActions = true;
    },

    setScrollListenter() {
      const vertcialProjectScrollEl = document.getElementsByClassName("vis-left vis-vertical-scroll");

      vertcialProjectScrollEl.forEach(el => {
        el.addEventListener("scroll", () => {
          if (!this.showStatusMenu) return;
          this.closeHandler();
        });
      });

      vertcialProjectScrollEl.forEach(el => {
        el.addEventListener("scroll", () => {
          if (!this.showForemanMenu) return;
          this.closeHandler();
        });
      });
    },

    // this is used to turn off the default behaviour of vis timeline parent group in order to allow click event in settings icon
    turnOfHammerEvents() {
      this.$refs.timeline.timeline.itemSet.groupHammer.off("tap");

      this.$refs.timeline.timeline.itemSet.groupHammer.on("tap", event => {
        let target = event.target;
        let hasClassAttribute = target.hasAttribute("class");
        if (!hasClassAttribute) return;

        // hide all the menus
        this.showStatusMenu = false;
        this.showForemanMenu = false;

        // project's setup
        let targetClassList = target.getAttribute("class").split(" ");
        if (targetClassList.includes("mdi-format-list-bulleted-square")) {
          const groupId = target.getAttribute("data-id");
          this.openProjectDialog(groupId);
        }

        // project's attributes (color)
        if (targetClassList.includes("mdi-cog")) {
          const position = event.target.getBoundingClientRect();
          const projectId = target.getAttribute("data-id");

          this.selectedProject = this.groups.find(group => group.id === projectId);
          this.selectedProjectStatus = this.selectedProject?.project?.projectStatus?.id;

          const menu = document.getElementById("project-status-menu");
          menu.style.position = "absolute";
          menu.style.left = `${position.x - 45}px`;
          menu.style.top = `${position.y - 50}px`;

          this.showStatusMenu = true;
        }

        // foreman's attributes (category and sort)
        else if (targetClassList.includes("mdi-crown") || targetClassList.includes("foreman-wrapper") || targetClassList.includes("foreman-wrapper-icon")) {
          const projectId = target.getAttribute("data-id");
          this.selectedProject = this.groups.find(group => group.id === projectId);

          const position = event.target.getBoundingClientRect();
          const menu = document.getElementById("foreman-menu");
          menu.style.position = "absolute";
          menu.style.left = `${position.x - 45}px`;
          menu.style.top = `${position.y - 50}px`;

          this.showForemanMenu = true;
        } else {
          this.$refs.timeline.timeline.itemSet._onGroupClick(event);
        }
      });
    },

    async toggleEmployeeForeman(isForeman, employeeId, projectId) {
      const data = await this.$apollo
        .mutate({
          mutation: toggleEmployeeForemanMutation,
          variables: {
            isForeman: isForeman,
            employeeId: employeeId,
            projectId: projectId
          }
        })
        .catch(error => {
          console.log(error);
        });

      return data.data.toggleEmployeeForeman;
    },

    //Opens dialog to start updating note on item. ItemId argument not to be confused with
    //timeline Id as argument is the id placed by component
    handleStartUpdateNote(itemId) {
      const item = this.$refs.timeline.visData.items._data[itemId];
      if (item.itemType !== "EMPLOYEE" && item.itemType !== "SUPPLIER") return;

      this.timelineToUpdateNote = item;
      this.showNotesDialog = !this.canReadOnly;
    },

    //Opens dialog to create new item for pre-existing nestedGroup (employee)
    handleStartCreation(e) {
      //if it has an item we return method to handle update notes
      if (e?.item) return this.handleStartUpdateNote(e.item);

      const { pageX, pageY, time, group } = e;
      if (!group || (!group.includes("E") && !group.includes("S"))) return;
      //need to adjust for window width to prevent hidden overflow
      const windowWidth = window.innerWidth;
      const totalRequiredWidth = pageX + 320;
      let xPosition = pageX;
      if (totalRequiredWidth > windowWidth) xPosition = windowWidth - 370;

      //Employee to add new item
      this.groupToCreateItem = this.groups.find(listGroup => listGroup.id === group);
      this.newItemStartDate = this.formatDate(time);
      this.newItemEndingDate = this.getEndDate(5, time);

      const menu = document.getElementById("item-creation-dialog");
      menu.style.position = "absolute";
      menu.style.left = `${xPosition - 50}px`;
      menu.style.top = `${pageY}px`;
      this.showItemCreationDialog = !this.canReadOnly;
    },

    closeItemCreationDialogHandler() {
      this.showItemCreationDialog = false;
      this.ItemCreationDialogKey++;
      this.groupToCreateItem = {};
      this.newItemStartDate = "";
      this.newItemEndingDate = "";
    },

    //Used for creating a new item in a pre-existing nested group(employee, supplier)
    async createNewItem(payload) {
      this.loadingCreation = true;
      const { name, ...rest } = payload;

      try {
        const { data } = await this.$apollo
          .mutate({
            mutation: saveOrUpdateTimelineMutation,
            variables: {
              ...rest
            }
          })
          .catch(error => {
            let errorMsg = this.translations.unableToUpdateTimeline;
            if (error.message.includes("end date exceeds employee")) {
              const msgArray = error.message.split(":")[3].split(" ");

              const contractEndDate = this.momentDate(msgArray[1].replaceAll("'", ""));
              errorMsg = `${this.translations.updateError}: ${this.translations.timelineDateForEmployee} ${name} ${this.translations.exceedsEndDate}: ${contractEndDate}.`;
            }

            this.errorHandler(errorMsg);
          });

        const edited = data.saveOrUpdateTimeline;
        this.prepNewData(edited);
      } catch (e) {
        console.log(e);
      }
      this.loadingCreation = false;
      this.closeItemCreationDialogHandler();
    },

    // Need to complete
    async deleteItemHandler(item) {
      try {
        const { timelineId } = item;
        const { data } = await this.$apollo
          .mutate({
            mutation: deleteTimelineMutation,
            variables: {
              timelineId: timelineId
            }
          })
          .catch(error => {
            console.log(error);
          });

        const edited = data.deleteTimeline;
        this.prepNewData(edited);
      } catch (e) {
        console.log(e);
      }
    },

    prepNewData(edited) {
      const arrangedData = edited;
      for (const projectId of Object.keys(arrangedData)) {
        //need to split projectId to get only number id
        const original = this.getOriginalInfo(projectId.split("P")[1]);
        const { groups, items } = arrangedData[projectId];
        const regularItems = [];
        const summaryItems = [];

        //get summary items segregated from employee items
        items.forEach(item => {
          if (item.itemType === "SUMMARY") {
            summaryItems.push(item);
          } else if (item.itemType === "EMPLOYEE" || item.itemType === "SUPPLIER") {
            item.name = item.content;
            // set content to empty string otherwise the employee's name will be displayed in the timeline
            item.content = "";
            regularItems.push(item);
          }
        });

        const editedProjectInfo = { groups, items: regularItems, summaryItems };
        this.handleProjectComparison(original, editedProjectInfo);
      }
    },

    async deleteItemFromDialog(item) {
      await this.deleteItemHandler(item);
      const { timelineId, itemType } = item;

      if (itemType === "EMPLOYEE") {
        this.employeeList = this.employeeList.filter(item => item.timelineId !== timelineId);
      } else {
        this.supplierList = this.supplierList.filter(item => item.timelineId !== timelineId);
      }
    },

    async editFromDialog(item) {
      const res = await this.editTimeline(item);
      const { group } = item;

      const groupId = group.split("-")[0];
      const projectId = groupId.split("P");

      this.employeeList = res[groupId].items
        .filter(item => {
          const { itemType, group } = item;
          const projectGroupId = group.split("-")[0];
          if (itemType === "EMPLOYEE" && groupId === projectGroupId) return item;
        })
        .map(item => {
          const { startDateStr, endDateStr, ...everythingElse } = item;

          return {
            end: endDateStr,
            start: startDateStr,
            projectId: projectId,
            ...everythingElse
          };
        })
        .sort((a, b) => (a.content > b.content ? 1 : b.content > a.content ? -1 : 0));

      this.supplierList = res[groupId].items
        .filter(item => {
          const { itemType, group } = item;
          const projectGroupId = group.split("-")[0];
          if (itemType === "SUPPLIER" && groupId === projectGroupId) return item;
        })
        .map(item => {
          const { startDateStr, endDateStr, ...everythingElse } = item;

          return {
            end: endDateStr,
            start: startDateStr,
            projectId: projectId,
            ...everythingElse
          };
        })
        .sort((a, b) => (a.content > b.content ? 1 : b.content > a.content ? -1 : 0));
    },

    openProjectDialog(groupId) {
      const group = this.groups.find(group => group.id === groupId);
      const projectId = group.project.id;

      this.employeeList = this.items
        .filter(item => {
          const { itemType, group } = item;
          const projectGroupId = group.split("-")[0];
          if (itemType === "EMPLOYEE" && groupId === projectGroupId) return item;
        })
        .map(item => {
          const { startDateStr, endDateStr, ...everythingElse } = item;

          return {
            end: endDateStr,
            start: startDateStr,
            projectId: projectId,
            ...everythingElse
          };
        })
        .sort((a, b) => (a.content > b.content ? 1 : b.content > a.content ? -1 : 0));

      this.supplierList = this.items
        .filter(item => {
          const { itemType, group } = item;
          const projectGroupId = group.split("-")[0];
          if (itemType === "SUPPLIER" && groupId === projectGroupId) return item;
        })
        .map(item => {
          const { startDateStr, endDateStr, ...everythingElse } = item;

          return {
            end: endDateStr,
            start: startDateStr,
            projectId: projectId,
            ...everythingElse
          };
        })
        .sort((a, b) => (a.content > b.content ? 1 : b.content > a.content ? -1 : 0));

      let projectTotalDays = 0;
      this.employeeList.forEach(item => {
        const { days } = item;
        projectTotalDays += days;
      });

      this.selectedProject = { ...group, allocatedDays: projectTotalDays };
      this.showProjectDialog = true;
    },

    //used to compare if new info should be requested
    compareStartAndEndDates(newStartDate, newEndDate) {
      const currentStartDate = this.availableRange.start;
      const currentEndDate = this.availableRange.end;
      if (new Date(newStartDate) > new Date(currentStartDate) && new Date(currentEndDate) > new Date(newEndDate)) return true;
      return false;
    },

    async changeRangeHandler(value) {
      try {
        const { start, end, byUser } = value;
        if (!byUser) return;
        const newRangeStartDate = this.formatDate(start);
        const newRangeEndDate = this.formatDate(end);
        //these will be used for next call
        let newStartDate = this.formatDate(start);
        let newEndDate = this.formatDate(end);

        //if no new dates no need to request new data
        const completelyOverlappedDates = this.compareStartAndEndDates(newRangeStartDate, newEndDate);
        if (completelyOverlappedDates) return;

        const currentStartDate = this.availableRange.start;
        const currentEndDate = this.availableRange.end;

        const startDateIsEarlier = new Date(newRangeStartDate) < new Date(currentStartDate);
        // if new start date is earlier date
        if (startDateIsEarlier) {
          this.availableRange.start = newRangeStartDate;
          newEndDate = currentStartDate;
        } else {
          this.availableRange.end = newRangeEndDate;
          newStartDate = currentEndDate;
        }

        const { data } = await this.$apollo.query({
          query: readTimelineQuery,
          variables: {
            startDateStr: newStartDate,
            endDateStr: newEndDate,
            productionCenterList: this.activeProductionCenters
          },
          fetchPolicy: "no-cache"
        });

        const newGroups = [];
        const newItems = [];
        for (const project of Object.keys(data.readTimelineData)) {
          const { groups, items } = data.readTimelineData[project];

          let order = 0;

          const mappedGroups = groups.map(group => {
            const { type, id } = group;

            if (type === "PROJECT") {
              order = group?.project?.position;
              return {
                ...group,
                order: order,
                className: `actual-project group-id-${id}`,
                showNested: false
              };
            }

            return {
              ...group,
              order: `${order}-${type[0]}-${group.content}`
            };
          });

          const mappedItems = items.map(item => {
            const { startDateStr, endDateStr, group, warning, itemType, note, content, ...everythingElse } = item;
            const start = `${startDateStr} 06:00:00`;
            const end = `${endDateStr} 18:00:00`;

            // If Project Item
            if (itemType === "PROJECT") {
              return {
                start,
                end,
                group,
                className: `vis-item-project-scope-${warning}`,
                itemType,
                ...everythingElse
              };
            } else if (itemType === "SUMMARY") {
              return {
                start,
                end,
                group,
                className: `vis-item-nested-project-${warning} vis-item-summary`,
                itemType,
                warning,
                ...everythingElse,
                content: ""
              };
            }

            return {
              start,
              end,
              group,
              className: `vis-item-nested-project-${warning} vis-item-scissor`,
              itemType,
              warning,
              title: note,
              name: content,
              ...everythingElse,
              content: note ? note : "",
              editable: {
                remove: true,
                updateGroup: false,
                updateTime: true
              }
            };
          });

          newGroups.push(...mappedGroups);
          newItems.push(...mappedItems);
        }

        const currentGroupIds = this.groups.map(group => group.id);
        newGroups.forEach(group => {
          const { id, type, nestedGroups } = group;
          if (type === "PROJECT") {
            const currentProject = this.groups.find(group => group.id === id);

            if (currentProject?.nestedGroups) {
              currentProject.nestedGroups = uniq([...currentProject.nestedGroups, ...nestedGroups]);
            }
          }
          if (!currentGroupIds.includes(id)) this.groups.push(group);
        });

        this.$nextTick(() => {
          this.setAdditionalAttributes();
        });

        const currentItemIds = this.items.map(item => item.timelineId);
        newItems.forEach(item => {
          const { itemType, timelineId } = item;
          if (itemType !== "EMPLOYEE" && itemType !== "SUPPLIER") {
            this.items.push(item);
          } else {
            if (!currentItemIds.includes(timelineId)) this.items.push(item);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },

    closeNotesDialogHandler() {
      this.showNotesDialog = false;
      this.notesDialogKey++;
      this.timelineToUpdateNot = {};
    },

    async updateNoteHandler(payload) {
      this.loadingNotesUpdates = true;
      try {
        const { timelineId, note } = payload;

        await this.$apollo.mutate({
          mutation: updateNote,
          variables: {
            timelineId,
            note
          }
        });

        const itemToUpdate = this.items.find(item => item.timelineId === timelineId);
        itemToUpdate.title = note;
        itemToUpdate.content = note;
      } catch (e) {
        console.log(e);
      }

      this.loadingNotesUpdates = false;
      this.closeNotesDialogHandler();
    },

    errorHandler(msg) {
      this.errorMessage = msg;
      this.showErrorMsgDialog = true;
    },

    async changeReadTimeline(productionCentersValue) {
      let newProductionCenters = this.activeProductionCenters;

      if (productionCentersValue) {
        newProductionCenters = productionCentersValue;
        this.$emit("set-active-centers", productionCentersValue);
      }

      try {
        const { end, start } = this.availableRange;
        const { data } = await this.$apollo.query({
          query: readTimelineQuery,
          variables: {
            startDateStr: start,
            endDateStr: end,
            productionCenterList: newProductionCenters
          },
          fetchPolicy: "no-cache"
        });

        const newGroups = [];
        const newItems = [];
        for (const project of Object.keys(data.readTimelineData)) {
          const { groups, items } = data.readTimelineData[project];
          let order = 0;

          const mappedGroups = groups.map(group => {
            const { type, id } = group;

            if (type === "PROJECT") {
              order = group?.project?.position;
              return {
                ...group,
                order: order,
                className: `actual-project group-id-${id}`,
                showNested: false
              };
            }

            return {
              ...group,
              order: `${order}-${type[0]}-${group.content}`
            };
          });

          const mappedItems = items.map(item => {
            const { startDateStr, endDateStr, group, warning, itemType, note, content, ...everythingElse } = item;
            const start = `${startDateStr} 06:00:00`;
            const end = `${endDateStr} 18:00:00`;

            // PROJECT item
            if (itemType === "PROJECT") {
              return {
                start,
                end,
                group,
                className: `vis-item-project-scope-${warning}`,
                itemType,
                ...everythingElse
              };
            }

            // SUMMARY item
            else if (itemType === "SUMMARY") {
              return {
                start,
                end,
                group,
                className: `vis-item-nested-project-${warning} vis-item-summary`,
                itemType,
                warning,
                ...everythingElse,
                content: ""
              };
            }

            // EMPLOYEE SUPPLIER item
            return {
              start,
              end,
              group,
              className: `vis-item-nested-project-${warning} vis-item-scissor`,
              itemType,
              warning,
              title: note,
              name: content,
              ...everythingElse,
              content: note ? note : "",
              editable: {
                remove: true,
                updateGroup: false,
                updateTime: true
              }
            };
          });

          newGroups.push(...mappedGroups);
          newItems.push(...mappedItems);
        }

        this.groups = newGroups;
        this.items = newItems;
        this.$nextTick(() => {
          this.setAdditionalAttributes();

          if (this.projectSearch !== "") {
            this.searchForProjects(this.projectSearch);
          }
        });
        this.loadingOrderChange = false;
      } catch (e) {
        console.log(e);
      }
    },

    removeProductionCenter(productionCenter) {
      this.$emit("remove-production-center", productionCenter);
      const productionCentersValue = this.activeProductionCenters.filter(pc => pc !== productionCenter);
      this.changeReadTimeline(productionCentersValue);
    },

    searchForProjects(value, resetAll = false) {
      const indexOfElementToSelect = this.showProjectOrderDialog ? 1 : 0;
      let lowerCaseValue = value.toLowerCase();
      const filteredProjects = [];
      const projectsToReset = [];

      this.projectCodeAndNames.forEach(project => {
        const keyCode = project.split(" ")[0];
        if (project.toLowerCase().includes(lowerCaseValue)) {
          filteredProjects.push(keyCode);
        } else {
          projectsToReset.push(keyCode);
        }
      });

      this.filteredProjectsLength = filteredProjects.length;

      //Reset non filtered projects
      projectsToReset.forEach(keyCode => {
        const el = document.getElementsByClassName(keyCode)[indexOfElementToSelect];
        let innerText = el.innerText;
        el.innerHTML = innerText;

        if (resetAll) {
          const el = document.getElementsByClassName(keyCode)[this.showProjectOrderDialog ? 0 : 1];
          let innerText = el.innerText;
          el.innerHTML = innerText;
        }
      });

      if (filteredProjects.length <= 0) {
        this.filteredProjectsActive = 0;
        return;
      }

      filteredProjects.forEach((keyCode, projectIndex) => {
        const el = document.getElementsByClassName(keyCode)[indexOfElementToSelect];
        let innerHTML = el.innerHTML;
        let innerText = el.innerText;
        let innerTextLowerCase = el.innerText.toLowerCase();
        const index = innerTextLowerCase.match(lowerCaseValue).index;

        if (index >= 0) {
          innerHTML = innerText.substring(0, index) + `<span class='${projectIndex === 0 ? "active-highlight" : "highlight"}'>` + innerText.substring(index, index + value.length) + "</span>" + innerText.substring(index + value.length);
          el.innerHTML = innerHTML;
        } else {
          innerHTML = innerText;
          el.innerHTML = innerHTML;
        }
      });

      this.filteredProjects = filteredProjects;

      this.filteredProjectsActive = 1;

      document.getElementsByClassName(this.filteredProjects[0])[indexOfElementToSelect].scrollIntoView(this.scrollOptions);
    },

    updateValueAndSearchForProjects(value) {
      this.projectSearch = value;
      this.searchForProjects(value, true);
    },

    scrollToNextProject(payload) {
      if (this.filteredProjectsLength <= 0) return;
      const { value, text } = payload;
      const indexOfElementToSelect = this.showProjectOrderDialog ? 1 : 0;

      let activeIndex = 0;
      if (value) {
        if (this.filteredProjectsActive + 1 <= this.filteredProjectsLength) {
          activeIndex = this.filteredProjectsActive;
          this.filteredProjectsActive++;
        } else {
          this.filteredProjectsActive = 1;
        }
        document.getElementsByClassName(this.filteredProjects[this.filteredProjectsActive - 1])[indexOfElementToSelect].scrollIntoView(this.scrollOptions);
      } else {
        if (this.filteredProjectsActive - 1 === 0) {
          activeIndex = this.filteredProjectsLength - 1;
          this.filteredProjectsActive = this.filteredProjectsLength;
        } else {
          this.filteredProjectsActive--;
          activeIndex = this.filteredProjectsActive - 1;
        }
        document.getElementsByClassName(this.filteredProjects[this.filteredProjectsActive - 1])[indexOfElementToSelect].scrollIntoView(this.scrollOptions);
      }

      this.filteredProjects.forEach((project, projectIndex) => {
        const el = document.getElementsByClassName(project)[indexOfElementToSelect];
        let lowerCaseValue = text.toLowerCase();
        let innerHTML = el.innerHTML;
        let innerText = el.innerText;
        let innerTextLowerCase = el.innerText.toLowerCase();
        const index = innerTextLowerCase.match(lowerCaseValue).index;

        innerHTML =
          innerText.substring(0, index) + `<span class='${projectIndex === activeIndex ? "active-highlight" : "highlight"}'>` + innerText.substring(index, index + text.length) + "</span>" + innerText.substring(index + text.length);
        el.innerHTML = innerHTML;
      });
    },

    projectOrderDialogCloseHandler() {
      this.showProjectOrderDialog = false;
      this.projectOrderDialogKey++;
    },

    collapseAllProjects() {
      this.groups.forEach(group => {
        const { type } = group;

        if (type === "PROJECT") {
          group.showNested = false;
        } else {
          group["visible"] = false;
        }
      });
    },

    expandAllProjects() {
      this.groups.forEach(group => {
        const { type } = group;

        if (type === "PROJECT") {
          group.showNested = true;
        } else {
          group["visible"] = true;
        }
      });
    },

    //used to add or remove scissor class
    itemOverHandler(e) {
      const item = this.$refs.timeline.timeline.itemsData._data[e.item];
      if (item.itemType !== "EMPLOYEE") return;

      let action = "add";
      if (!this.setScissorMode) action = "remove";

      e.event.target.classList[action]("scissor");
    },

    timelineRedrawn() {
      const visVerticalScroll = document.getElementsByClassName('vis-vertical-scroll');
      if (visVerticalScroll.length > 0) {
        visVerticalScroll[0].style.left = '-17px';
      }
    },

    async handleClick(e) {
      // return if not using scissor functionality or click was not on item
      if (!this.setScissorMode || e.what !== "item") return;

      //get item
      const item = this.$refs.timeline.timeline.itemsData._data[e.item];

      // return if item is not employee item
      if (item.itemType !== "EMPLOYEE") return;

      try {
        //get dates
        const clickedDate = this.formatDate(e.time);
        const itemStartDate = item.start.split(" ")[0];
        const itemEndDate = item.end.split(" ")[0];

        //find group
        const itemGroupId = item.group;
        const group = this.groups.find(group => group.id === itemGroupId);

        //assing date in proper format for edit method
        const itemToEdit = item;
        itemToEdit.start = new Date(itemStartDate);
        itemToEdit.end = new Date(clickedDate);

        let nextDay = new Date(clickedDate);
        nextDay.setDate(nextDay.getDate() + 1);

        const ids = item.group.split("-");
        //payload to create new timeline
        const createNewPayload = {
          isForeman: group.foreman,
          timelineId: null,
          employeeId: item.itemType === "EMPLOYEE" ? Number(ids[1].split("E")[1]) : null,
          supplierId: item.itemType === "SUPPLIER" ? Number(ids[1].split("S")[1]) : null,
          startDateStr: nextDay.toISOString().substr(0, 10),
          projectId: Number(ids[0].split("P")[1]),
          endDateStr: itemEndDate,
          name: item.name
        };

        //edit first timeline
        await this.editTimeline(itemToEdit);

        //create new timeline after split
        await this.createNewItem(createNewPayload);

        this.setScissorMode = false;
        this.options.selectable = true;
      } catch (e) {
        console.log(e);
      }
    },

    removeSelected() {
      this.$refs.timeline.timeline.setSelection();
    }
  },

  computed: {
    translations: () => translations,

    icons: () => ({ mdiAccountSearch, mdiArrowAll, mdiCog, mdiArrowDownDropCircle, mdiArrowUpDropCircle, mdiOrderNumericAscending, mdiTruckDeliveryOutline, mdiArrowCollapse, mdiArrowExpand, mdiContentCut, mdiScissorsCutting }),

    statusList() {
      if (!this.getProjectStatusList || this.getProjectStatusList?.length <= 0) return [];

      return this.getProjectStatusList;
    },

    employeesComputed() {
      if (!this.employeeNameFilter) return this.availableEmployeesByEndDate;
      return this.availableEmployeesByEndDate.filter(employee => employee.name.toLowerCase().includes(this.employeeNameFilter.toLowerCase()));
    },

    mainColor() {
      return this.$vuetify.theme.themes.light.main;
    },

    projectCodeAndNames() {
      return this.groups
        .filter(group => group.type === "PROJECT")
        .sort((a, b) => (a.project.position > b.project.position ? 1 : b.project.position > a.project.position ? -1 : 0))
        .map(project => {
          const { keyCode, label } = project.project;
          return `${keyCode} ${label}`;
        });
    },

    groupsTypeProject() {
      return this.groups
        .filter(project => project.type === "PROJECT")
        .map(project => {
          const { position, keyCode, label, projectStatus, id } = project.project;

          return { position, keyCode, label, projectStatus, id };
        })
        .sort((a, b) => (a.position > b.position ? 1 : b.position > a.position ? -1 : 0));
    },

    filterSuppliers() {
      if (!this.suppliers) return [];
      return this.suppliers.filter(s => s.description.toLowerCase().indexOf(this.supplierNameFilter.toLowerCase()) > -1).map(a => ({ ...a, name: a.description }));
    },

    supplierItems() {
      return this.items.filter(item => item.itemType === "SUPPLIER");
    },

    supplierGroups() {
      return this.groups.filter(group => group.type === "SUPPLIER");
    },

    disableScrollBtns() {
      if (this.filteredProjectsLength <= 0) return true;
      if (!this.projectSearch) return true;
      return false;
    },

    employeeGroupsCheck() {
      const filteredGroups = this.groups.filter(group => group.type === "EMPLOYEE");

      const obj = {};

      filteredGroups.forEach(emp => {
        const { content, id, order } = emp;

        const projectId = id.split("-")[0];
        const projectCode = this.groups.find(group => group.id === projectId).project.keyCode;
        let cont = content.split(" ").join("-");
        obj[`${cont}-${projectCode}`] = order;
      });

      return obj;
    },

    projectGroupsCheck() {
      const filteredGroups = this.groups.filter(group => group.type === "PROJECT");

      const obj = {};

      filteredGroups.forEach(pro => {
        const { content, order, project } = pro;

        let cont = content.split(" ").join("-");
        obj[order] = `${project.keyCode}-${cont}`;
      });

      return obj;
    }
  },

  watch: {
    squadNameFilter() {
      this.getFilteredQuads();
    },

    activeProductionCenters(newVal) {
      this.activeProductionCentersCopy = newVal;
    }
  }
};
</script>

<style>
@import "../../../../node_modules/vue2vis/dist/vue2vis.css";
.vis-label {
  width: 250px !important;
  max-width: 250px !important;
}

.vis-item-project-scope-0 {
  background-color: #7c7c80;
  color: white;
  border-color: #7e7e7e;
  border-radius: 10%;
  font-size: 8px;
  max-height: 8px;
}

.vis-item-project-scope-1 {
  background-color: rgb(26, 45, 226);
  color: white;
  border-color: rgb(52, 62, 155);
  border-radius: 10%;
  font-size: 8px;
  max-height: 8px;
}

.vis-item.vis-selected {
  background-color: rgba(0, 0, 0, 0.25);
  border: 2px dashed rgba(0, 0, 0, 0.5);
}

.vis-item-nested-project-0 {
  background-color: black;
  color: #000;
  border-color: black;
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-nested-project-1 {
  background-color: rgb(68, 251, 68, 0.75);
  color: #000;
  border-color: rgb(2, 186, 2, 0.9);
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-nested-project-2 {
  background-color: rgb(250, 250, 136, 0.75);
  color: #000;
  border-color: rgb(177, 177, 0, 0.9);
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-nested-project-3 {
  background-color: rgba(255, 0, 0, 0.75);
  color: #000;
  border-color: rgb(158, 0, 0, 0.9%);
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-nested-project-4 {
  background-color: rgba(128, 0, 128, 0.55);
  color: #000;
  border-color: rgba(128, 0, 128, 0.55);
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-summary {
  font-size: 10px;
  max-height: 15px;
  margin-top: 15px;
}

.vis-saturday,
.vis-sunday {
  background-color: rgba(0, 0, 0, 0.05);
}

.vis-text.vis-saturday,
.vis-text.vis-sunday {
  background: rgba(250, 250, 250, 0.9);
}

.vis-text.vis-minor.vis-saturday {
  background-color: #104272;
  color: white;
}

.vis-text.vis-minor.vis-sunday {
  background-color: #104272;
  color: white;
}

.vis-text.vis-minor {
  text-align: center;
  border-radius: 5px 5px 0 0;
}

.vis-item.vis-range {
  border-radius: 5px !important;
}

.group-template {
  width: 100%;
  padding: 4px !important;
}

.group-template-is-foreman {
  background-color: #f5c716 !important;
}

.group-template-not-foreman {
  background-color: rgba(152, 152, 152, 0.76) !important;
}

.group-template-items {
  display: flex;
  align-items: center;
  justify-items: left;
  color: white;
}

.group-template-items-supplier-wrapper {
  display: flex;
  align-items: center;
  justify-items: left;
  color: #000;
}

.project-template {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-wrapper-1 {
  border-left: 4px solid #104272;
  background-color: #cbdeff;
  padding: 0px;
  color: #4d4d4d !important;
}

.project-wrapper-2 {
  border-left: 4px solid #104272;
  background-color: #7cbdfc;
  padding: 0px;
  color: #4d4d4d !important;
}
.project-wrapper-3 {
  border-left: 4px solid #104272;
  background-color: #005ab1;
  padding: 0px;
  color: white !important;
}

.project-code-name {
  margin: 12px 4px;
}

.vis-label.vis-nesting-group:before,
.vis-label.vis-nesting-group:before {
  display: none;
}

i {
  /* cursor: move; */
  margin-right: 4px;
}

.vis-january:first-letter {
  text-transform: uppercase;
}
.vis-february:first-letter {
  text-transform: uppercase;
}
.vis-march:first-letter {
  text-transform: uppercase;
}
.vis-april:first-letter {
  text-transform: uppercase;
}
.vis-may:first-letter {
  text-transform: uppercase;
}
.vis-june:first-letter {
  text-transform: uppercase;
}
.vis-july:first-letter {
  text-transform: uppercase;
}
.vis-august:first-letter {
  text-transform: uppercase;
}
.vis-september:first-letter {
  text-transform: uppercase;
}
.vis-october:first-letter {
  text-transform: uppercase;
}
.vis-november:first-letter {
  text-transform: uppercase;
}
.vis-december:first-letter {
  text-transform: uppercase;
}

.vis-item .vis-delete {
  top: -12px !important;
}

.vis-inner {
  width: 100%;
}

.vis-inner:not(.group-template) {
  border-top: 1px solid #000 !important;
}

.card-lists-tabs {
  position: sticky;
  top: 0;
  border-top: 4px solid #104272;
}

.icon-container {
  padding: 4px;
}

.settings-icon {
  cursor: pointer !important;
}

.hover-row:hover {
  background-color: #d9d9d9;
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.vis-item-overflow > .vis-item-content {
  padding: 1px;
  /* opacity: 0; */
}

.vis-item.vis-readonly > .vis-item-overflow > .vis-item-content {
  opacity: 0;
}

.vis-timeline > .vis-tooltip {
  max-width: 250px !important;
  width: 250px !important;
  min-height: 20px;
  display: inline-block !important;
  word-break: break-word !important;
  overflow: auto;
  overflow-x: hidden;
  white-space: pre-line;
}

.highlight {
  background-color: yellow;
}

.active-highlight {
  background-color: #ff9632;
}

.project-search.v-input > .v-input__control > .v-input__slot > .v-input__append-inner {
  margin: 4px 0px;
}

.project-search.v-input > .v-input__control > .v-input__slot > .v-input__append-inner > .project-count {
  padding: 8px 0px 8px 0px !important;
  min-width: 60px;
  font-size: 14px;
}

.project-search.v-input > .v-input__append-outer {
  margin: 0px 0px 0px 2px !important;
}
/*
.supplier-icon {
  color: var(mainColor);
} */

.vis-item-scissor.scissor:hover {
  cursor: URL("../../../assets/content-cut.png") 12 8, auto !important;
}

.group-template-supplier {
  color: #000 !important;
  background-color: #f0f0f0 !important;
  width: 100%;
  padding: 4px !important;
}

.vis-foreground .vis-group.actual-project {
  border-top: 1px solid #000 !important;
}

.expand-collapse-buttons {
  max-width: 250px;
  position: absolute;
  z-index: 99;
  left: 35px;
  top: 64px;
}

.foreman-wrapper {
  position: absolute;
  right: 3px;
  padding: 1px 6px 1px 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1px;
  border-radius: 25px;
  transition: all .5s;
  background-color: rgb(164, 139, 0);
}

.foreman-wrapper * {
    text-align: center;
}

.foreman-wrapper:hover {
  background-color: rgba(164, 139, 0, 0.6);
}
</style>
